/* Need Help */
/*-------------new style added----------------*/

.review_contractor_popup{
  /* border: 1px solid blue !important; */
  padding-top: 10px !important;
}
.cross{
  margin-bottom: 10px;
}

@media (max-width:443px) {
 /*  .Rating{
    justify-content: start;
  } */
  .review_contractor_heading b{
    font-size: 25px;
  }
}
@media (max-width: 630px) {
  .review_contractor_popup{
    width: 94vw !important;
  }
  .review_contractor_popup{
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}
/* ----------------------------------- */
.grid_sec {
  gap: 2rem;
  display: grid;
  padding: 0 2%;
  width: 100%;
  /* grid-template-columns: repeat(5, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(10rem, 15rem));
  justify-content: center;

 
}


.grid_item {
  width: 100% !important;
  margin-bottom: 10px;
  
  
}

.inner {
  width: 100%;
  aspect-ratio: 1/1;
  transition: 0.25s;
  cursor: pointer;
  border-radius: 15px;
  background-size: cover;
  background-position: center;
  margin: auto;
 
}

.inner:hover {
  box-shadow: 5px 5px #303030;
  transform: translate(-5px, -5px);
}

.main_list {
  position: relative;
 
}

.nested_list {
  width: 100%;
  padding: 1rem;
  text-align: left;
  border-radius: 15px;
  background-color: lightblue;
  /* border: 1px solid red;
  overflow-y: scroll;
  max-height: 30vh; */
}

.comp_inner {
  height: 75px;
  cursor: pointer;
  border-radius: 15px;
  background-size: cover;
  background-position: center;
 
}

.icon_img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.inner_list {
  margin: 1rem 0;
  padding: 1rem;
  text-align: left;
  border-radius: 15px;
  background-color: yellow;
}

.inner_list p {
  padding: 0.5rem 1rem;
  border-radius: 100px;
}

.inner_list p:hover {
  color: white;
  cursor: pointer;
  background-color: blue;
}

.inner_SubList p:hover {
  background-color: green;
}

/* New Service Request */

.iframe_map {
  border: 0;
  width: 100%;
  min-height: 100%;
}

.desc_box {
  border: 0;
  width: 100%;
  padding: 15px;
  outline: inherit;
  font-size: 1rem;
  border-radius: 5px;
  background-color: lightgray;
}

@media (min-width:400px) {
  .top_box {
    display: flex;
    

  }

}


.top_box_inner {
  /* width: 18vw; */
  gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* border:1px solid black;  */
  /* margin: 4px; */
}

.inner_img {
  height: 70px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid black;
}

.image_upload img {
  height: 10rem;
  cursor: pointer;
  margin-right: 0.25rem;
}

.pop_show {
  width: 50%;
}

.main_con {
  display: flex;
  /* flex-direction: column; */
}

.main_child {
  width: 50%;
}

@media (max-width:800px) {
  .main_con {
    flex-direction: column !important;
  }

  .main_child {
    width: 100%;
  }

  .iframe_map {
    height: 50vh;
  }
}




.parent_container {
  height: 100vh;
  overflow-y: auto;

}

.child_container {
  /* height: 100vh; */
  padding: 1rem;
  /* width: 50%; */
  /* overflow-x: hidden; */
  /* overflow-y: scroll; */
}

@media only screen and (max-width: 500px) {
  .parent_container {
    height: auto;
    overflow: scroll;
   
  }



  .child_container {
    height: auto;
    overflow: auto;
  }

  .grid_sec {
    display: block;
    margin-bottom: 70px;
   
   
  
  }

  .iframe_map {
    aspect-ratio: 1/1.5;
  }

  .pop_show {
    width: 100%;
  }

  .nested_list {
    width: 100%;
    margin-left: 0;
    position: static;
  }

  .inner {
    width: 80%;
    cursor: pointer;
    aspect-ratio: 1/1;
    transition: 0.25s;
    border-radius: 15px;
    background-size: cover;
    background-position: center;
    margin: auto;
  
  }
}
.mainheading{
  font-size: 40px !important;
}

