.main_typo{
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.modal_main{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  /* border: 2px solid blue; */
}
.custom_btn5 {
  color: red;
  padding: 12px 0;
  border: 1px solid red;
  width: 35%;
  border-radius: 20px;
  transition: 0.3s ease;
  cursor: pointer;
}
.custom_btn5:hover {
  background: red;
  color: white;
  transition: 0.3s ease;
}
.custom_btn4 {
  color: white;
  padding: 12px 0;
  background: green;
  border: 1px solid green;
  cursor: pointer;
  width: 35%;
  border-radius: 20px;
  transition: 0.3s ease;
}
.custom_btn4:hover {
  background: none;
  color: green;
  transition: 0.3s ease;
}
.mainDiv1{
  display: flex;
  justify-content: space-between;
  /* border: 2px solid red; */
}
.delete_div{
  /* border: 1px solid red; */
  width: 50%;
}
.custom_delete{
  width: 100%;
  border-radius: 20px;
  padding: 12px 0;
  cursor: pointer;
  transition: 0.3s ease;
  color: white  ;
  background: red;
  border: 1px solid red;
}
/* .custom_delete:hover{
  background: none;
  color: black;
  transition: 0.3s ease;
} */
.custom_btn1 {
  color: black;
  padding: 12px 0;
  /* border: 1px solid  white; */
  width: 100%;
  border-radius: 20px;
  transition: 0.3s ease;
  cursor: pointer;
  background: white;
  border: 1px solid black;
}
.custom_btn1:hover {
  background: black;
  color: white;
  transition: 0.3s ease;
}
.custom_btn2 {
  color: white;
  padding: 12px 0;
  background: green;
  border: 1px solid green;
  cursor: pointer;
  width: 100%;
  border-radius: 20px;
  transition: 0.3s ease;
}
.custom_btn2:hover {
  background: none;
  color: green;
  transition: 0.3s ease;
}
experience_width {
  width: 560px;
  border-radius: 2px solid red;
}

.addedBox {
  border: 1px solid rgb(201, 199, 199);
  border-radius: 5px;
}

.custom_btn3 {
  color: white;
  padding: 12px 20px;
  background: red;
  cursor: pointer;
  border: 1px solid red;
  *width: 100%;
  border-radius: 10px;
  transition: 0.3s ease;
}

.text {
  padding: 10px 0;
  font-weight: 600;
}
.email {
  padding: 10px 0;
  font-weight: 600;
  margin-right: 12px;
  
}
.mainDiv{
    display: flex;
}

.text1 {
  font-weight: 600;
  color: rgb(133, 132, 132);
}
