.grid_sec {
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* padding: -20px 0 0 0; */
}

.grid_sec div {
  width: 100%;
  display: flex;
  aspect-ratio: 1/1;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

.grid_sec .left {
  background-image: url(/public/images/consumer.png);
  background-color: yellow;
  transition: 0.25s;
}

.grid_sec .right {
  background-image: url(/public/images/contractor.png);
  background-color: blue;
  transition: 0.25s;
}

.grid_sec .left:hover,
.grid_sec .right:hover {
  transform: translate(-5px, -5px);
  box-shadow: 10px 10px #303030;
}
.store{
  display: flex;
  justify-content: center ;
}
.storeimg{
  display: flex;
  width: 25%;
  justify-content: space-between;
}

@media only screen and (max-width: 500px) {
  .store{
    margin-top: -30px;
    margin-bottom: 10px;
  }
  .storeimg{
   
    width: auto;
    gap: 10px;

  }

  .grid_sec {
    display: block;
    margin-top: -30px !important;
  }

  .grid_sec div {
    width: 65vw;
    margin: 2rem auto;
  }
}
@media only screen and (max-width: 1000px) and (min-width: 500px)
{
  .storeimg{
    width: 52%;
  }
}

