@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800;900);

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
}

a {
  text-decoration: none;
  color: inherit;
}

input[type="file"] {
  display: none;
}

/* Image Classes */

.image_0 {
  background-image: url(/public/images/1.png);
}

.image_1 {
  background-image: url(/public/images/2.png);
}
.image_2 {
  background-image: url(/public/images/3.png);
}
.image_3 {
  background-image: url(/public/images/4.png);
}
.image_4 {
  background-image: url(/public/images/5.png);
}

.close{
  display: flex;
  justify-content: end;
  width: 100%;
  background: white;
  position: sticky;
  top: 0;
}
.d_flex_between{
  display: flex;
  justify-content: space-between;
}

.d_flex_end{
  display: flex;
  justify-content: end;
}

.custom_btnH{
  font-size: 12px !important;
  cursor: pointer;
  background: green;
  color: white;
  border-radius: 10px;
  padding: 3px 5px 0 5px;
}

.custom_btnR{
  font-size: 12px !important;
  cursor: pointer;
  background: #add8e6;
  font-weight: 500 !important;
  border-radius: 10px;
  padding: 3px 5px 0 5px;
}
.text_center{
  text-align: center;
}

.fs_18{
  font-size: 18px !important;
}